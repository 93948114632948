{
    "en": {
        "translation": {
            "begin_msg": "To start using Adno, please enter the URL of an IIIF manifest or an IIIF image a static picture in jpg or png format, or an Adno project",
            "create_project": " Create a new project",
            "import": {
                "import_project": "Import a project",
                "import_now": "Using a project file",
                "validate": "Validate",
                "cancel": "Cancel",
                "adno_project": "Adno project found, would you like to import it ?",
                "import_success": "Project imported successfully !"
            },
            "projects": {
                "all": "Your existing projects",
                "nothing": "Nothing to show, create your first project to start using Adno"
            },
            "project": {
                "created_on": "Created on ",
                "last_update": "Last update on ",
                "no_desc": "No description available for this project",
                "no_creator": "No author indicated",
                "no_editor": "No editor indicated",
                "preview": "Preview",
                "edit": "Edit",
                "delete": "Delete",
                "duplicate": "Duplicate",
                "download": "Download",
                "new": "Create my own project",
                "new_tooltip": "Create a new project",
                "title": "Title",
                "description": "Description",
                "author": "Author",
                "editor": "Editor",
                "manifest_url": "URL",
                "create": "Create my new project",
                "back": "Back",
                "add_desc": "Add a little text to describe your project (optional)",
                "add_title": "Add a title to your project",
                "copy": "Copy",
                "cancel": "Cancel",
                "choose": "Select an image",
                "choose_desc": "Choose the image you want to use for your project",
                "choose_canva": "Use this picture for my project",
                "back_home": "Cancel and go back to homepage",
                "metadatas": {
                    "identifier": "Identifier",
                    "source": "Source",
                    "creation_date": "Created on",
                    "last_update": "Last update on",
                    "format": "Format",
                    "rights": "Distributed rights",
                    "allocation_rights": "Allocation of rights"
                },
                "metadatas_plh": {
                    "title": "Give a title to your project",
                    "description": "Add a little text about your artwork",
                    "author": "Add the author of the artwork here",
                    "editor": "Add the editor of the artwork here",
                    "allocation_rights": "Fill in here the rights of the artwork"
                },
                "settings": {
                    "delay": "Delay between 2 annotations (in seconds)",
                    "outline_width": "Annotations outline width",
                    "outline_color": "Annotation outline color",
                    "outline_focus": "Color of annotation outline with focus",
                    "navigator": "Annotations Map browser",
                    "fullscreen": "Show toolbar in full screen mode",
                    "annos_nav": "Show navigation bar for annotations",
                    "begin_first_anno": "Always start autoplay at first annotation",
                    "enable_rota": "Enable image rotation",
                    "toolsbar": "Enable toolsbar",
                    "save": "Save",
                    "tags": "Only annotations including at least one of this tag will be displayed",
                    "show_outlines": "Display the outlines",
                    "show_eyes": "Display icon inside shapes",
                    "no_sound": "Audio annotation wont be played",
                    "spatialization": "The audio annotations will be played together with a spatialization effect",
                    "no_spatialization": "Audio annotations will be played individually",
                    "annotation_sound": "Choose a mode for audio annotations",
                    "visualization": "Visualization",
                    "navigation": "Navigation",
                    "annotation": "Annotation",
                    "anno_bounds": "Are annotations visible ?"
                }
            },
            "files": {
                "one_selected": "1 file selected"
            },
            "buttons": {
                "cancel": "Cancel",
                "close": "Close"
            },
            "editor": {
                "approve_changes": "Approve changes",
                "md_add_tag": "Add a new tag",
                "audio_track": "Audio Track (URL)",
                "audio_creator": "Audio Creator (Credit)",
                "md_save": "Save",
                "md_delete": "Delete",
                "md_delete_confirm": "Confirm deletion",
                "fullpage": "Toggle full page",
                "home": "Go home",
                "zoom_in": "Zoom in",
                "zoom_out": "Zoom out",
                "next_page": "Next page",
                "previous_page": "Previous page",
                "rotate_left": "Rotate left",
                "rotate_right": "Rotate right",
                "flip": "Flip Horizontally",
                "placeholder": "Enter text here",
                "tabs": {
                    "editor": "Editor",
                    "tags": "Tags", 
                    "audio": "Audio"
                }
            },
            "visualizer": {
                "expand": "Enter full screen",
                "pause": "Pause",
                "play": "Play",
                "reset_zoom": "Zoom",
                "toggle_annotations": "Hide/show annotations",
                "previous_annotation": "Go to previous annotation",
                "next_annotation": "Go to next annotation",
                "rotation": "Rotate of 90 degrees",
                "info": "Information",
                "help_title": "Keyboard shortcuts",
                "help_key": "key",
                "help_key_plural": "keys",
                "help_or": "or",
                "help_and": "and",
                "help_key_p": "to start the autoplay,",
                "help_key_e": "to toggle full screen,",
                "help_key_escape": "to exit full screen mode,",
                "help_key_s": "to toggle annotation bounds,",
                "help_key_t": "to toggle the toolsbar,",
                "help_key_arrows": "arrows (left and right) to change the current selected annotation.",
                "help_doc": "For more information, please consult the official documentation of Adno",
                "close": "Fermer"
            },
            "modal": {
                "delete_project": "Would you really want to delete this project ?",
                "confirm_delete": "Yes, delete my project",
                "cancel": "Cancel",
                "projects_list_up_to_date": "Projects list have been updated",
                "duplicate_project": "Would you really want to duplicate this project ?",
                "confirm_duplication": "Yes, duplicate this project",
                "annotation_moved": "Annotation moved successfully",
                "ask_delete": "Would you really like to delete this annotation ?",
                "confirm_del_annotation": "Yes, delete this annotation",
                "del_annotation_confirmation": "Annotation deleted successfully",
                "project_edit_success": "Your project have been updated successfully !",
                "settings_updated_success": "Settings updated successfully !",
                "adno_proj_detected": "Adno project detected, would you like to import it ?",
                "old_version": "One or more projects were made with an obsolete version of Adno",
                "update_old_version": "Update to the latest version of Adno",
                "version_updated": "Congratulations, your Adno projects are now up to date"
            },
            "annotation": {
                "no_content": "no content yet",
                "read_more": "Read more",
                "edit": "Edit",
                "target": "Focus annotation",
                "switch_down": "Switch down",
                "switch_up": "Switch up",
                "delete": "Remove annotation",
                "tags_list": "Filter annotations using tags",
                "empty_tags_list": "The created tags will be displayed here."
            },
            "navbar": {
                "edit_mode": "Edit mode",
                "view_mode": "View mode",
                "undo": "Reverse the previous action",
                "redo": "Reapply the undone action",
                "back_home": "Back to home",
                "download_project": "Download the project",
                "edit_project": "Edit project metadata",
                "show_metadatas": "Show metadata",
                "share_project": "Save and share your project",
                "share_project_desc1": "First, download your project file. To share it, you can either send it by e-mail, or place it online - on a website, a file server or on the IPFS interplanetary file system - and then share its URL or IPFS CID.",
                "share_project_desc2": "Once your Adno project is online, you can make it visible on a web page by inserting an iframe tag. ",
                "share_project_desc3": "For more information, visit the official Adno documentation."
            },
            "new_project": {
                "add_info": "Add information to  your project"
            },
            "errors": {
                "error_found": "An error have been found",
                "unable_reading_file": "Unable to read this type of file !",
                "unable_access_file": "Unable to reach this file ! Please try again or select another file",
                "manifest_picture_not_found": "The manifest or the picture you have entered has not been found",
                "wrong_url": "The URL you have entered is not valid",
                "no_url": "No URL was provided",
                "unknown_characters": "Unable to read annotations (Forbidden characters)",
                "no_title": "Please, add a name to your project",
                "unable_reading_manifest": "Unable to read this manifest, please try again or choose another one",
                "no_iiif": "Non-IIIF project detected, please fill in an IIIF project",
                "wrong_file": "This file is not readable by Adno! Please try again with another file"
            },
            "tags_infos": "Please press your key 'enter' between each tag to save them",
            "link": {
                "url": "Add the URL to the IIIF ressources",
                "h1": "URL Generator",
                "preview": "URL preview",
                "generate": "Copy URL"
            }
        }
    }
}
